//
// Bootswatch
// -----------------------------------------------------



// Navbar =====================================================================

.navbar {

  border-bottom: 1px solid @gray-dark;
}

// Buttons ====================================================================

// Typography =================================================================

.text-primary,
.text-primary:hover {
  color: lighten(@brand-primary, 5.6%);
}

.text-success,
.text-success:hover {
  color: @brand-success;
}

.text-danger,
.text-danger:hover {
  color: @brand-danger;
}

.text-warning,
.text-warning:hover {
  color: @brand-warning;
}

.text-info,
.text-info:hover {
  color: @brand-info;
}

// Tables =====================================================================

.table {

  tr.success,
  tr.warning,
  tr.danger,
  tr.info {
    color: #fff;
  }
}

.table-responsive > .table {
  background-color: @table-bg;
}

// Forms ======================================================================

.has-warning {
  .help-block,
  .control-label {
    color: @brand-warning;
  }

  .form-control,
  .form-control:focus {
    border-color: @brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label {
    color: @brand-danger;
  }

  .form-control,
  .form-control:focus {
    border-color: @brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label {
    color: @brand-success;
  }

  .form-control,
  .form-control:focus {
    border-color: @brand-success;
  }
}

legend {
  color: #fff;
}

.input-group-addon {
  background-color: @btn-default-bg;
}

// Navs =======================================================================

.nav {

  .caret,
  a:hover .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
  }
}

.nav-tabs,
.nav-pills,
.breadcrumb,
.pager {

  a {
    color: #fff;
  }
}

// Indicators =================================================================

.alert {

  .alert-link,
  a {
    color: @alert-warning-text;
    text-decoration: underline;
  }
}

// Progress bars ==============================================================

// Containers =================================================================

.jumbotron {

  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}
