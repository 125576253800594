//
// Custom Includes
// --------------------------------------------------

.buffers(@suffix; @size) {
	.margin-@{suffix} { margin: @size; }
	.margin-top-@{suffix} { margin-top: @size; }
	.margin-right-@{suffix} { margin-right: @size; }
	.margin-bottom-@{suffix} { margin-bottom: @size; }
	.margin-left-@{suffix} { margin-left: @size; }
	.margin-vertical-@{suffix} { margin-top: @size; margin-bottom: @size; }
	.margin-horizontal-@{suffix} { margin-left: @size; margin-right: @size; }
	.padding-@{suffix} { padding: @size; }
	.padding-top-@{suffix} { padding-top: @size; }
	.padding-right-@{suffix} { padding-right: @size; }
	.padding-bottom-@{suffix} { padding-bottom: @size; }
	.padding-left-@{suffix} { padding-left: @size; }
	.padding-vertical-@{suffix} { padding-top: @size; padding-bottom: @size; }
	.padding-horizontal-@{suffix} { padding-left: @size; padding-right: @size; }
}

.buffers(xs, @padding-xs-horizontal);
.buffers(sm, @padding-small-horizontal);
.buffers(md, @padding-base-horizontal);
.buffers(lg, @padding-large-horizontal);
.buffers(xl, @padding-xl-horizontal);

.resize-none{ resize: none; }
.resize-horizontal { resize: horizontal; }
.resize-vertical { resize: vertical; }

.monospace {
	font-family: monospace, monospace;
}